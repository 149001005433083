<template>
  <div class="home">
    <h3 class="common-title">平台列表</h3>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="listFilter.keyword" placeholder="请输入关键词" clearable
                  @keyup.enter="getPlatformList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getPlatformList">
          检索
        </el-button>
      </div>
      <div class="fr">
        <el-button type="primary" @click="newPlatform">追加</el-button>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="list.length === 0"></Empty>
      <el-table
        :data="list"
        class="home-list"
        style="width: 100%"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="identifier"
          label="平台ID">
        </el-table-column>
        <el-table-column
          prop="jwt_algorithm"
          label="Jwt Algorithm">
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template #default="scope">
            <el-button
              type="primary"
              link
              @click="editPlatform(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="formRef" :title="form.id === null ? '新增':'编辑'" width="40%"
               destroy-on-close
               draggable>
      <span v-loading="loading"><el-form
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
        label-width="150px"
        class="home-form"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="平台ID" prop="identifier">
          <el-input v-model="form.identifier" placeholder="wxAPPId/域名"></el-input>
        </el-form-item>
        <el-form-item label="Jwt SecretKey" prop="jwt_secret_key">
          <el-input type="password" v-model="form.jwt_secret_key"
                    :placeholder="form.id ? '留空则不修改':''"
                    :show-password="true"></el-input>
        </el-form-item>
        <el-form-item label="Jwt Algorithm" prop="jwt_algorithm">
          <el-input v-model="form.jwt_algorithm"></el-input>
        </el-form-item>
        </el-form></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="formRef = false">取消</el-button>
        <el-button type="primary" @click="createPlatform">
          {{ form.id === null ? '新增' : '确定' }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { authApi } from '@/api'
import { onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    const loading = ref(false)
    // form

    const list = ref([])
    const formRaw = reactive({
      id: null,
      name: null,
      identifier: null,
      jwt_secret_key: null,
      jwt_algorithm: null,
    })
    const form = reactive(JSON.parse(JSON.stringify(formRaw)))
    const formRef = ref(false)

    const ruleFormRef = ref(null)

    const rules = reactive({
      name: [{ required: true, message: '请输入名称', trigger: 'blur' },],
      identifier: [{ required: true, message: '请输入平台ID', trigger: 'blur' },],
    })
    const listFilter = reactive({
      keyword: null,
    })
    const getPlatformList = () => {
      loading.value = true
      authApi.getPlatformList({ keyword: listFilter.keyword }).then(res => {
        console.log(res)
        list.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }
    const newPlatform = () => {
      Object.keys(formRaw).forEach(key => {
        form[key] = formRaw[key]
      })
      rules.jwt_secret_key = [{ required: true, message: '请输入Jwt SecretKey', trigger: 'blur' },]
      formRef.value = true
    }
    const editPlatform = (item) => {
      console.log(item)
      Object.keys(formRaw).forEach(key => {
        form[key] = item[key]
      })
      rules.jwt_secret_key = []
      formRef.value = true
    }
    const createPlatform = () => {
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          if (form.id === null) {
            authApi.createPlatform(form).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              formRef.value = false
              getPlatformList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updatePlatform(form.id, form).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              formRef.value = false
              getPlatformList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    onMounted(() => {
      getPlatformList()
    })
    return {
      list,
      form,
      formRef,
      newPlatform,
      createPlatform,
      ruleFormRef,
      rules,
      editPlatform,
      getPlatformList,
      listFilter,
      loading,
    }
  }
}
</script>
<style scoped>

</style>
